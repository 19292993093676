<template>
    <div v-loading="is_pending" ref="component_viewer_wrapper" class="component-viewer-wrapper slide-in-left">
        <div class="component-viewer-wrapper-header">
            <h2>Components Viewer</h2>
            <div @click="handle_close_model" class="exit-btn">
            <i class="material-symbols-rounded ">chevron_left</i>
            </div>
        </div>
        <div class="component-viewer-wrapper-content">
            <el-tree  ref="treeRef" :data="data" node-key="id" :props="defaultProps" />
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { getCurrentInstance} from '@vue/runtime-core'
import router from '../../router'

export default {
    emits:['close_model'],
    setup(_,context){
        
        const component_viewer_wrapper = ref()

        const app = getCurrentInstance()
        const is_pending = ref(false)

        const defaultProps = {
            children: 'children',
            label: 'label',
            disabled: 'disabled',
        }

        const treeRef = ref()
        const data = ref([])
        
        const handle_close_model = () => {
            component_viewer_wrapper.value.classList.remove('slide-in-left')
            component_viewer_wrapper.value.classList.add('slide-out-left')
            setTimeout(() => {
                context.emit('close_model')
            }, 500);
        }

        const sleep = ()=>{
            return new Promise((resolve)=>setTimeout(resolve,50))
        }
    
        const organized_nested_obj = async(obj, result=[]) => {  
            const r = result
            for (const [key, value] of Object.entries(obj)) {
                if(!value.components && value.render && !value.emit){
                    let functions = Object.entries(value.setup()).filter(([key, value]) =>  value instanceof Function);
                    functions = Object.fromEntries(functions);
                    let variables = Object.entries(value.setup()).filter(([key, value]) =>  !(value instanceof Function));
                    variables = Object.fromEntries(variables);
                    await sleep()
                    r.push({
                    id:key,
                    label: key,
                    children: [
                        {
                            label: 'Functions',
                            children: await organized_nested_obj(functions)
                        },
                        {
                            label: 'Variables',
                            children: await organized_nested_obj(variables)
                        },
                    ]
                    })
                }
                else if(!value.components && value.__asyncLoader && !value.emit){
                    const res = await value.__asyncLoader()
                    //const functions_and_variables = res.setup()
                    let functions = Object.entries(res.setup()).filter(([key, value]) =>  value instanceof Function);
                    functions = Object.fromEntries(functions);
                    let variables = Object.entries(res.setup()).filter(([key, value]) =>  !(value instanceof Function));
                    variables = Object.fromEntries(variables);
                    await sleep()
                    r.push({
                    id:key,
                    label:key,
                    children: [
                        {
                            label: 'Functions',
                            children: await organized_nested_obj(functions)
                        },
                        {
                            label: 'Variables',
                            children: await organized_nested_obj(variables)
                        },
                    ]
                    })
                }
                else if(!value.components && !value.render && !value.__asyncLoader){
                    r.push({
                    id:key,
                    label:`${value instanceof Function?'<Function>':'<VAR>'} ${key} 
                    ${(!(value instanceof Function) && (typeof value._value == 'boolean'
                     || typeof value._value == 'string' || value._value == null )) ? '('+JSON.stringify(value._value)+')':''}`,
                    })
                }
                else if(value.components && value.__asyncLoader && !value.emit){
                    const res = await value.__asyncLoader()
                    let functions = Object.entries(res.setup()).filter(([key, value]) =>  value instanceof Function);
                    functions = Object.fromEntries(functions);
                    let variables = Object.entries(res.setup()).filter(([key, value]) =>  !(value instanceof Function));
                    variables = Object.fromEntries(variables);
                    await sleep()
                    r.push({
                        id:key,
                        label:key,
                        children: [
                            {
                                label:'components',
                                children: await organized_nested_obj(value.components)
                            },
                            {
                                label: 'Functions',
                                children: await organized_nested_obj(functions)
                            },
                            {
                                label: 'Variables',
                                children: await organized_nested_obj(variables)
                            },
                        ]
                    })
                }
                else if(value.components && value.render && !value.emit){
                    let functions = Object.entries(value.setup()).filter(([key, value]) =>  value instanceof Function);
                    functions = Object.fromEntries(functions);
                    let variables = Object.entries(value.setup()).filter(([key, value]) =>  !(value instanceof Function));
                    variables = Object.fromEntries(variables);
                    await sleep()
                    r.push({
                        id:key,
                        label:key,
                        children: [
                            {
                                label:'components',
                                children: await organized_nested_obj(value.components)
                            },
                            {
                                label: 'Functions',
                                children: await organized_nested_obj(functions)
                            },
                            {
                                label: 'Variables',
                                children: await organized_nested_obj(variables)
                            },
                        ]
                    })
                }
                else if(value.components && !value.render && !value.__asyncLoader){
                    await sleep()
                    r.push({
                        id:key,
                        label: key,
                        children: await organized_nested_obj(value.components)
                    })
                }
            }
            return r
        }

        const get_components_by_router = async()=>{
            const path = router.getRoutes().find(route=>route.path==router.currentRoute._value.path)
            const component_name = path.components.default.__file.split('/').pop().split('.')[0]
            const res = {
                label:component_name,
                children: await organized_nested_obj(path.components)
            }

            return res
        }

        const start = async() => {
            return new Promise(async(resolve)=>{
                const res = await organized_nested_obj(app.root.components)
                resolve(res)
            })
        }

        const init = async() => {
            is_pending.value = true
            data.value = await start()
            if(router.currentRoute._value.path!='/'){
                data.value.push(await get_components_by_router()) 
            }
            is_pending.value = false
        }

        init()

        return{
            data,
            defaultProps,
            treeRef,
            is_pending,
            handle_close_model,
            component_viewer_wrapper,
        }
    }
}
</script>

<style scoped>

  .component-viewer-wrapper{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: max-content;
    height: 90%;
    background: #fff;
    border-radius: 0 10px 0 0;
    padding: 0 10px;
  }

  .slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .component-viewer-wrapper-header{
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .component-viewer-wrapper-content{
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    overflow-y: auto;
  }
  .component-viewer-edit{
    background-color: whitesmoke;
    border-radius: 10px;
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 50px);
    height: 50px;
    transform: translateY(0);
    opacity: 0;
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    z-index: 12;
  }
  .component-viewer-edit.show-edit{
    opacity: 1;
    transform: translateY(-75px);
    width: 100%;
    height: 70px;
  }
  .component-viewer-edit-header{
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  .component-viewer-edit-content{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 5px;
  }
  .exit-btn{
    transform: translateY(-40px);
    right: 0;
    left: unset;
  }
  .exit-btn i{
    font-size: 25px;
  }
 
</style>